@keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }

    50% {
        border-radius: 10% 80% 90% 20% / 30% 50% 20% 50%;
    }

    100% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
}

@keyframes explosion {
	0% {
		top: 100%;
	}
    
	33% {
        top: -50%;
    }

    100% {
        top: -50%;
	}
}
