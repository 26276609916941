.pie-chart-wrapper {
    position: relative;

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 32px;
        color: var(--text-primary);
    }
}