.apexcharts-tooltip {
    background: $white-color;
    color: $black-color;
    text-align: center;
    box-shadow: 0 12px 24px rgba(128,144,208,0.15) !important;

    .apexcharts-tooltip-title {
        margin: 0;
        padding-bottom: 0;
        color: $grey-color-2;
    }
}

.apexcharts-label {
    tspan {
        fill: var(--text-secondary);
    }
}