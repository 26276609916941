body {
  background-color: var(--background) !important;
  will-change: background-color;
  transition: background-color 0.25s ease;

  &::-webkit-scrollbar {
    width: 1em;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background: $primary-color;
    border-radius: 100px;
    background-clip: content-box;
  } 
}

.app {
  display: flex;
  flex-wrap: nowrap;
  width: calc(100vw + 260px);
  -webkit-transform: translateX(-260px);
  transform: translateX(-260px);
  will-change: transform;
  transition: transform 0.25s ease;

  &.toggle {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  @media (min-width: 768px) {
    width: calc(100vw + 248px + 48px);
  }

  @media (min-width: 1024px) {
    width: auto;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    > .container {
      max-width: calc(100vw + 248px + 48px);
    }
  }

  .container {
    max-width: var(--max-width);
    will-change: max-width;
    transition: max-width 0.25s ease;
  }
}

.content {
  min-width: 100vw;
  padding-bottom: 32px;

  > .container {
    min-height: calc(100vh - 152px);
  }

  @media (min-width: 992px) {
    min-width: 0;
    width: 100%;
  }

  @media (min-width: 768px) {
    > .container {
      max-width: 100vw;
    }
  }

  @media (min-width: 992px) {
    > .container {
      min-height: calc(100vh - 72px);
    }
  }
}

.title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 0;

  @media (min-width: 992px) {
    position: sticky;
    top: 15px;
    padding: 15px;
    margin-left: -15px;
    margin-right: -15px;
    z-index: 999;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 12px;
      background-color: var(--bg-title-bar);
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      box-shadow: 0 12px 24px 0 var(--box-shadow);
      opacity: 0;
      will-change: opacity;
      transition: opacity 250ms ease;
      z-index: 0;
    }

    &.scrolling {
      &::before {
        opacity: 1;
      }
    }
  }
}

.bo-nav-toggle {
  display: flex;
  align-items: center;
  width: 24px;

  i {
    display: none;
    font-size: 24px;

    &.open {
      display: block;
    }
  }
}

// ------- start : react-select ---------
.search-select {
  &__control {
    min-height: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
    border-width: 0 !important;
    background: transparent !important;
    padding: 1.625rem 2.25rem 0.625rem 0.75rem !important;
  }

  &__value-container,
  &__input-container {
    padding: 0 !important;
    margin: 0 !important;
  }

  &__indicator {
    display: none !important;
  }
}

// -------- end : react-select ----------

.profile-button {
  position: relative;
  cursor: pointer;

  .profile-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      display: block;
    }
  }

  .profile-menu {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    min-width: 200px;
    background: var(--background-card);
    box-shadow: 0 12px 24px 0 var(--box-shadow);
    border-radius: 16px;
    border: 1px solid rgba($grey-color-3, 0.1);
    z-index: 99;
  }

  .profile-link {
    padding: 16px 20px;

    a,
    div {
      display: block;
      padding: 4px 0;
      color: var(--text-primary);
      text-shadow: 0 0 0 rgba($black-color, 0);
      transition: all 0.25s ease;

      &:hover {
        color: $primary-color;
        text-shadow: 0 2px 4px rgba($black-color, 0.1);
      }
    }
  }
}

.profile-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    display: block;
  }

  @media (min-width: 992px) {
    .profile-image {
      width: 40px;
      height: 40px;
    }
  }
}

.bank-logo {
  width: 36px;
  margin-right: 12px;
}

.card-account {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 16px;
  background: var(--background-card);
  box-shadow: 0 12px 24px 0 var(--box-shadow);
  border-radius: 16px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 5px;
  }

  img {
    width: 64px;
    margin-right: 16px;
    border-radius: 16px;
    box-shadow: 0 8px 16px rgba(128, 144, 208, 0.3);
  }

  i {
    color: var(--heading);
    font-size: 20px;
    transition: all 0.25s ease;
    cursor: pointer;

    &:hover {
      color: $primary-color;
    }
  }

  .card-account-info {
    h4 {
      font-weight: bold;
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
      color: $grey-color-2;
    }
  }

  &.active {
    &::before {
      background-color: $green-color;
    }
  }

  &.inactive {
    background: var(--background-card);
    opacity: 0.75;

    .card-account-info {
      opacity: 0.6;
    }

    img {
      opacity: 0.75;
    }

    &::before {
      background-color: $red-color;
    }
  }

  @media (min-width: 992px) {
    padding: 24px;

    img {
      margin-right: 24px;
    }
  }
}

.form-box {
  background: #ffffff;
  box-shadow: 0 12px 24px 0 var(--box-shadow);
  border-radius: 16px;
}

.form-code-control {
  width: 56px;
  height: 72px;
  font-size: 64px;
  margin: 0 8px;
  text-align: center;
  box-shadow: 0 12px 24px 0 var(--box-shadow);
  border: 1px solid #e6e8f1 !important;
}

.box-white {
  background: var(--background-card);
  box-shadow: 0 12px 24px 0 var(--box-shadow);
  border-radius: 16px;
  padding: 16px;

  &.scroll {
    height: 300px;
    max-height: 300px;
    overflow: auto;
  }

  &.box-promotion {
    display: flex;
    flex-direction: column;
    height: 100%;
    // background-color: var(--background-card);
    border-radius: 16px;
    overflow: hidden;

    .ant-card-actions {
      margin: auto 0 0 !important;
    }

    // .ant-card-meta-title {
    //     color: var(--heading);
    // }

    // .ant-card-meta-description {
    //     color: var(--text-secondary);
    // }

    // .ant-card-actions {
    //     background-color: var(--background-card);
    //     margin-top: auto;
    //     border-top-color: var(--input-border);

    //     > li:not(:last-child) {
    //         border-right-color: var(--input-border);
    //     }
    // }
  }

  @media (min-width: 992px) {
    padding: 24px;
  }
}

.amount-label {
  width: calc(100% / 3);

  p {
    color: var(--text-secondary);
    margin-bottom: 8px;
  }

  h5 {
    margin-bottom: 0;

    span {
      color: $primary-color;
      font-weight: normal;
    }
  }
}

.user-card {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 18px;

  .user-info {
    padding-left: 16px;
    line-height: 1.15;

    p {
      font-size: 16px;
      color: var(--text-primary);
      margin-bottom: 0;

      small {
        color: var(--text-third);
      }
    }
  }
}

.bo-navbar {
  background: var(--bg-title-bar) !important;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);

  .profile-icon,
  .bo-nav-toggle i {
    color: var(--text-primary);
  }

  .profile-icon {
    padding: 0 !important;
  }
}

.modal-content {
  background-color: var(--background-card);
  border-radius: 16px;
}

.permission-list {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;

  .permission-name {
    position: relative;
    color: var(--text-primary);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -0.5rem;
    right: -0.5rem;
    bottom: 0;
    border-radius: 8px;
    background: var(--background-card-hover);
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.25s ease;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
}

.sub-title {
  display: block;
  font-size: 16px;
  color: var(--text-secondary);
  margin-top: 8px;

  @media (min-width: 992px) {
    display: inline-block;
    font-size: 18px;
    margin-top: 0;
    margin-left: 16px;
  }
}

.box-list {
  color: var(--text-primary);

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .box-list-head {
    display: flex;
    border-bottom: 1px solid rgba($grey-color-3, 0.2);
    padding-bottom: 16px;
    margin-bottom: 12px;

    div div:first-child {
      font-weight: bold;
    }

    span {
      color: $primary-color;
    }
  }

  .box-list-footer {
    small {
      display: block;
    }
  }

  .text-status-warning {
    color: $primary-color;
  }

  .text-status-danger {
    color: $red-color;
  }
}

.filter-wrapper {
  border-top: 1px solid rgba($grey-color-3, 0.25);
  border-bottom: 1px solid rgba($grey-color-3, 0.25);
  padding-top: 16px;

  .filter-group {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    label {
      white-space: nowrap;
      min-width: 64px;
    }

    .ant-select {
      width: 100%;
      padding-left: 8px;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;

    .filter-group {
      label {
        min-width: auto;
      }

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}

.loading {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.name-prefix {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    top: calc(50% + 15px);
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba($primary-color, 0.5);
    width: 60px;
    height: 30px;
    border-radius: 50%;
    -webkit-filter: blur(16px);
    filter: blur(16px);
  }

  &.name-prefix-mobile {
    width: auto;
  }

  .name-prefix-text {
    position: relative;
    color: var(--heading);
    font-size: 24px;
    font-weight: bold;
    font-style: italic;
    text-shadow: 0 4px 4px var(--text-shadow);
    margin: 0 auto;
  }

  .name-prefix-shape {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(
      135deg,
      rgba($primary-color, 0) 0%,
      rgba($primary-color, 0.3) 100%
    );
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    width: 56px;
    height: 56px;
    transition: all 0.5s ease-in-out;
    animation: morph 2s ease-in-out infinite;
    z-index: -1;

    &:nth-child(2) {
      width: 84px;
      height: 48px;
      animation: morph 4s ease-in-out infinite reverse;
    }
  }
}

.firework {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: scale(0.4);
  z-index: 0;

  .explosion::before {
    animation: explosion 3s 0.4s ease-in-out infinite;
  }
}

.explosion {
  position: absolute;
  left: -2px;
  bottom: 0;
  width: 4px;
  height: 80px;
  transform-origin: 50% 100%;
  overflow: hidden;

  &:nth-child(1) {
    transform: rotate(0deg) translateY(-15px);
  }
  &:nth-child(2) {
    transform: rotate(30deg) translateY(-15px);
  }
  &:nth-child(3) {
    transform: rotate(60deg) translateY(-15px);
  }
  &:nth-child(4) {
    transform: rotate(90deg) translateY(-15px);
  }
  &:nth-child(5) {
    transform: rotate(120deg) translateY(-15px);
  }
  &:nth-child(6) {
    transform: rotate(150deg) translateY(-15px);
  }
  &:nth-child(7) {
    transform: rotate(180deg) translateY(-15px);
  }
  &:nth-child(8) {
    transform: rotate(210deg) translateY(-15px);
  }
  &:nth-child(9) {
    transform: rotate(240deg) translateY(-15px);
  }
  &:nth-child(10) {
    transform: rotate(270deg) translateY(-15px);
  }
  &:nth-child(11) {
    transform: rotate(300deg) translateY(-15px);
  }
  &:nth-child(12) {
    transform: rotate(330deg) translateY(-15px);
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 40px;
    background-color: rgba($primary-color, 1);
  }
}

.profile-char {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: linear-gradient(
    135deg,
    rgba($primary-color, 0) 0%,
    rgba($primary-color, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--heading);
  font-weight: bold;
  text-shadow: 0 2px 8px var(--text-shadow);
  text-transform: uppercase;
  @include btn-box-shadow($primary-color);
}

.profile-info {
  padding: 0 8px;

  .profile-username {
    font-weight: bold;
    line-height: 1;
    color: var(--heading);
  }

  .profile-permission {
    font-size: 12px;
    color: var(--text-secondary);
  }
}

.profile-chevron-down {
  color: var(--text-primary);
  display: none !important;

  @media (min-width: 992px) {
    display: block !important;
  }
}

.amount-card {
  display: flex;
  align-items: center;
  background: var(--background-card);
  box-shadow: 0 12px 24px 0 var(--box-shadow);
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 24px;

  &.no-box {
    padding: 0;
    box-shadow: 0 0 0 0;
  }

  .amount-card-icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-right: 12px;
    border-radius: 8px;

    &.icon-primary {
      background: linear-gradient(
        315deg,
        rgba($primary, 0.15) 0%,
        rgba($primary, 0.01) 100%
      );
      color: $primary;
    }

    &.icon-third {
      background: linear-gradient(
        315deg,
        rgba($blue-color, 0.15) 0%,
        rgba($blue-color, 0.01) 100%
      );
      color: $blue-color;
    }

    &.icon-info {
      background: linear-gradient(
        315deg,
        rgba($info, 0.15) 0%,
        rgba($info, 0.01) 100%
      );
      color: $info;
    }

    &.icon-warning {
      background: linear-gradient(
        315deg,
        rgba($warning, 0.15) 0%,
        rgba($warning, 0.01) 100%
      );
      color: $warning;
    }

    &.icon-success {
      background: linear-gradient(
        315deg,
        rgba($success, 0.15) 0%,
        rgba($success, 0.01) 100%
      );
      color: $success;
    }

    &.icon-danger {
      background: linear-gradient(
        315deg,
        rgba($danger, 0.15) 0%,
        rgba($danger, 0.01) 100%
      );
      color: $danger;
    }

    img {
      width: 48px;
    }
  }

  .amount-card-text {
    .amount-card-text-title {
      font-size: 14px;
    }
    .amount-card-text-amount {
      font-size: 20px;
      font-weight: bold;
      color: var(--text-primary);

      span {
        color: $primary;
      }
    }

    .text-third {
      color: $blue-color;
    }
  }
}

.checkbox-collapse {
  padding: 16px;
  background: var(--background);
  border-radius: 12px;

  @media (min-width: 768px) {
    padding: 16px 24px 24px 24px;
  }
}

.drawer-actions {
  text-align: center;

  button {
    min-width: 120px;
  }
}

.text-link {
  .row-name {
    transition: color 0.25s ease;
  }

  &:hover {
    .row-name {
      color: $blue-color;
    }
  }
}