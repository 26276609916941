@import "variables";
@import "theme";

.sidebar {
  position: sticky;
  top: 0;
  width: 248px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 24px 0 24px 12px;

  @media (min-width: 992px) {
    padding: 32px 0 32px 12px;
  }

  .sidebar-logo {
    width: 150px;
    margin: 2px 0 32px;
    display: block;
  }

  .nav-pills {
    margin: 0 -15px;

    .nav-link {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px 24px 12px 0;
      border-radius: 5px;
      color: var(--text-primary);

      i {
        width: 56px;
        text-align: center;
        font-size: 20px;
        transition: all 0.25s ease;
      }

      .fa-chevron-down {
        width: auto;
        font-size: 14px;
        display: flex;
        margin-left: auto;
        transform-origin: center center;
      }

      &:not(.active) {
        i {
          color: var(--text-primary);
        }

        &:hover {
          color: var(--text-primary-hover);

          i:not(.fa-chevron-down) {
            color: $primary-color;
          }
        }
      }

      &[aria-expanded="true"] {
        .fa-chevron-down {
          transform: rotate(180deg);
        }
      }
    }
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--text-active);
    font-weight: bold;
    background-color: transparent;

    i {
      color: $primary-color;
    }
  }
}

.sidebar-footer {
  position: relative;
  font-size: 16px;
  color: var(--text-secondary);
  margin: auto -15px 0 !important;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 24px;
    height: 1px;
    background-color: rgba(142, 142, 142, 0.2);
  }
}

.nav-sub-item {
  padding-left: 56px;

  .nav-sub-link {
    display: block;
    margin: 6px 0;
    color: var(--text-secondary);

    &.active {
      color: var(--text-active);
      font-weight: bold;
    }

    &:hover {
      color: var(--text-active);
    }
  }
}

.sidebar-menu {
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 1em;
  }
  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background: $primary-color;
    border-radius: 100px;
    background-clip: content-box;
  }
}
