@mixin btn-box-shadow($color, $isHover: false){
    box-shadow: 0 6px 12px 0 rgba($color, 0.2);

    @if $isHover {
        &:hover {
            box-shadow: 0 8px 16px 0 rgba($color, 0.16);
        }
    }
}

@mixin bo-badge-color($color){
    background: rgba($color, 0.15);
    color: $color;
}