.swal2-popup {
    border-radius: 8px !important;
    background: var(--background-card) !important;
    color: var(--text-secondary) !important;
}

.swal2-actions {
    button {
        border-radius: 8px !important;
    }

    .swal2-confirm  {
        color: #000 !important;
        background-color: $primary-color !important;
    }
}

.swal2-warning {
    border-color: $primary-color !important;
    color: $primary-color !important;
}

.swal2-input {
    border-color: var(--input-border) !important;
    border-radius: 8px !important;
    color: var(--text-primary) !important;

    &:focus {
        border: 1px solid $primary-color !important;
        box-shadow: 0 0 0 0 !important;
    }
}