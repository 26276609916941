:root {
    --background: #{$grey-color-1};
    --background-card: #{$white-color};
    --background-card-hover: #{darken($white-color, 3%)};
    --heading: #{$black-color};
    --text-primary: #{$grey-color-2};
    --text-primary-hover: #{$black-color};
    --text-secondary: #{$grey-color-3};
    --text-secondary-hover: #{$grey-color-2};
    --text-third: #{lighten($grey-color-3, 5%)};
    --text-third-hover: #{$grey-color-3};
    --text-active: #{$black-color};
    --text-shadow: rgba(255,255,255, .3);
    --icon: #{rgba(0, 0, 0, 0.25)};
    --icon-disabled: #{rgba(0, 0, 0, 0.1)};
    --line: #{rgba(0, 0, 0, 0.1)};
    --input-on-background: #{$white-color};
    --input-border: #d9d9d9;
    --bg-light: #f8f9fa;
    --bg-radio: #{$white-color};
    --bg-title-bar: rgba(255, 255, 255, 0.9);
    --box-shadow: rgb(128, 144, 208, 0.15);
}

[data-is-dark='true'] {
    --background: #{$black-color};
    --background-card: #{darken($white-color, 90%)};
    --background-card-hover: #{lighten($black-color, 8%)};
    --heading: #{$white-color};
    --text-primary: #{darken($white-color, 15%)};
    --text-primary-hover: #{$white-color};
    --text-secondary: #{darken($white-color, 40%)};
    --text-secondary-hover: #{darken($white-color, 15%)};
    --text-third: #{darken($white-color, 60%)};
    --text-third-hover: #{darken($white-color, 40%)};
    --text-active: #{$white-color};
    --text-shadow: rgba(0, 0, 0, 0);
    --icon: #{rgba(255, 255, 255, 0.3)};
    --icon-disabled: #{rgba(255, 255, 255, 0.1)};
    --line: #{rgba(255, 255, 255, 0.1)};
    --input-on-background: #{$black-color};
    --input-border: #3d3d3d;
    --bg-light: #1c1c1c;
    --bg-radio: #1c1c1c;
    --bg-title-bar: rgba(0, 0, 0, 0.9);
    --box-shadow: rgb(0, 0, 0, 0.5);
}


[data-is-fullscreen='false'] {
    --max-width: 1320px;
}

[data-is-fullscreen='true'] {
    --max-width: 100%;
}