.swiper-banner {
    padding-bottom: 24px !important;

    .swiper-pagination {
        bottom: 0px !important;

        .swiper-pagination-bullet {
            background-color:  var(--text-active) !important;
        }

        .swiper-pagination-bullet-active {
            background-color: $primary-color !important;
        }
    }

    .swiper-slide {
        img {
            width: 100%;
            display: block;
            border-radius: 16px;
            overflow: hidden;
        }
    }

    @media (min-width: 992px) {
        padding-top: 26px !important;
    }
}