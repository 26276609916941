.radio-setting-theme {
    .ant-radio-wrapper {
        position: relative;

        .ant-radio {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            input[type="radio"] {
                width: 100%;
                height: 100%;
            }

            .ant-radio-inner {
                width: 100%;
                height: 100%;
                border-radius: 8px;
                background-color: transparent !important;
            }
        }

        .ant-radio-checked {
            .ant-radio-inner {
                border-width: 3px;

                &::after {
                    display: none;
                }
            }

            &::after {
                border-radius: 8px;
            }
        }

        span.ant-radio + * {
            padding-right: 0;
            padding-left: 0;
            border-radius: 8px;
            overflow: hidden;
        }
    }
}