body {
    font-family: 'Roboto', 'Noto Sans Thai', sans-serif !important;
    font-size: 16px !important;
    margin: 0;
    padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

h2, h5 {
    font-weight: 700 !important;
}

h1, h2, h3, h4, h5, h6 {
    color: var(--heading) !important;
    font-weight: 700 !important;
}