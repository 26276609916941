// Row
.row-datetime {
    color: var(--text-third);

    span {
        color: var(--text-primary);
    }
}

.row-member {
    color: var(--text-primary);

    .row-member-phone {
        i {
            color: $success;
        }

        span {
            color: var(--text-third);
        }
    }
}

.row-bank {
    display: flex;
    align-items: center;

    img {
        width: 32px;
        border-radius: 8px;
    }

    .row-bank-info {
        padding-left: 8px;
        color: var(--text-primary);

        span {
            color: var(--text-third);
        }
    }
}

.row-amount {
    font-size: 18px;
    font-weight: bold;
    color: var(--text-primary);

    span {
        color: $primary-color;
    }
}